<template>
    <div class="lucky-container" v-if="showResult">
        <div v-if="!hasAction">
            <div class="empty-action">
                <div class="inner">
                    <img src="./empty.png"/>
                    <p class="p1">暂无活动</p>
                    <p class="p2">活动已经结束啦～下次再来吧！</p>
                </div>
            </div>
        </div>
        <div class="view-container" v-if="hasAction">
            <img class="bg" src="./turn-table-bg.png"/>
            <div class="action-name">{{info.turntable_name}}</div>
            <div class="lucky-container">
                <LuckyWheel
                        ref="LuckyWheel"
                        class="turn-table"
                        width="300px"
                        height="300px"
                        :prizes="prizes"
                        :blocks="blocks"
                        :buttons="buttons"
                        @start="startCallBack"
                        @end="endCallBack"
                />
            </div>

            <div class="limit-times">
                <div class="inner">
                    您还可以抽奖 <b>{{info.limit_times}}</b> 次
                </div>
            </div>

            <div class="gift-list">
                <div class="inner">
                    <div class="gift-title">中奖信息</div>
                    <ul class="gift-container scrollbar">
                        <li v-for="(item,index) in giftRecords" :key="index">恭喜您，抽中 {{item.gift_name}} 1份</li>
                    </ul>
                </div>
            </div>

            <div class="rules" @click="showRules">
                <div>《活动规则》</div>
            </div>

            <div class="warn">
                <div class="inner">
                    本活动最终解释权归 {{info.store_name}} 所有
                </div>
            </div>

            <nut-popup class="popup" position="bottom" :style="{ padding: '30px 0',height:'50%' }"
                       v-model:visible="showPopup">
                <div v-html="info.rules" style="padding: 0 30px;"></div>
            </nut-popup>
        </div>
    </div>
</template>

<script>

  export default {
    name: 'turn-table',
    mounted () {
      document.title = '营销活动'
      this.storeId = this.$route?.query?.id || 0
      this.id = this.$route?.query?.aid || 0
      this.user_id = this.$route?.query?.user_id || 0
      this.token = this.$route?.query?.token || ''
      this.getTurntableInfo()
      this.getGiftRecords()
    },
    data () {
      return {
        id: 0,
        storeId: 0,
        userInfo: {},
        user_id: '',
        token: '',
        info: {},
        blocks: [
          {padding: '3px', background: '#ffc27a'},
          {padding: '3px', background: '#ff4a4c'},
          {padding: '0px', background: '#fff'}
        ],
        prizes: [],
        buttons: [
          {radius: '40px', background: '#F9B98C'},
          {radius: '35px', background: '#fff', pointer: true},
          {
            radius: '30px',
            background: '#F9B98C',
            fonts: [{text: '抽奖', top: '-13px'}]
          }
        ],
        giftRecords: [],
        hasAction: true,
        showResult: false,
        showPopup: false
      }
    },
    methods: {
      getTurntableInfo () {
        this.$api.actives.getTurnTableSetting({id: this.id}).then(response => {
          if (typeof response.data.id == 'undefined') {
            this.hasAction = false
            document.title = '幸运抽奖'
          } else {
            this.info = response.data
            document.title = this.info.turntable_name
            let prizes = []
            this.info.goods.forEach(item => {
              prizes.push({
                name: item.goods_name,
                background: item.bg_color,
                fonts: [{text: item.goods_name, top: '12%'}],
                imgs: [{src: item.cover_path, width: item.cover_width, top: '35%'}],
                color:'#fff'
              })
            })
            this.prizes = prizes
          }

          this.showResult = true

        })
      },
      getGiftRecords () {
        this.$api.actives.getTurnTableGiftRecord({id: this.id}).then(response => {
          this.giftRecords = response.data
        })
      },
      startCallBack () {
        let limit_times = parseInt(this.info.limit_times)
        if (limit_times > 0) {
          this.$refs.LuckyWheel.play()
          //调用接口，查询获信息
          this.$api.actives.getTurnTableGift({id: this.id, user_id: this.user_id}).then(response => {
            console.log('response', response)
            this.$refs.LuckyWheel.stop(response.data)
          })
        } else {
          this.$dialog({
            title: "温馨提示",
            content: '您的抽奖次数已用尽',
            noFooter:true,
            closeBtn:true
          });
        }

      },
      endCallBack () {
        let limit_times = parseInt(this.info.limit_times)
        limit_times--
        this.info.limit_times = limit_times
        this.getGiftRecords()
      },
      showRules () {
        this.showPopup = true
      },
    }
  }
</script>

<style scoped lang="scss">
    .lucky-container {
        width: 100%;
        overflow: hidden;
        margin: 0 auto;

        .view-container {
            width: 100%;
            position: relative;

            .bg {
                width: 100%;
                z-index: 100;
            }

            .action-name {
                font-family: 'PingFangSC-Semibold';
                width: 100%;
                text-align: center;
                position: absolute;
                top: 40px;
                z-index: 999;
                font-size: 30px;
                color: #FEEDCF;
                text-shadow: 2px 2px 2px #C13D24;
            }

            .lucky-container {
                width: 100%;
                position: absolute;
                top: 100px;
                left: 0;
                display: flex;
                justify-content: center
            }

            .limit-times {
                position: absolute;
                top: 420px;
                left: 0;
                width: 100%;
                display: flex;
                justify-content: center;

                .inner {
                    color: #512B0D;
                    background: #FEA665;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    padding: 0 20px;
                    -webkit-border-radius: 20px;
                    -moz-border-radius: 20px;
                    border-radius: 20px;
                    font-size: 14px;
                    font-weight: bold;

                    b {
                        margin-left: 10px;
                        margin-right: 10px;
                        color:#D10900;
                        font-weight: bold;
                        font-size: 18px;
                    }
                }
            }

            .gift-list {
                position: absolute;
                top: 480px;
                left: 0;
                width: 100%;

                .inner {
                    background: linear-gradient(0deg, #FCAA7A 0%, #FED595 100%);
                    margin: 0 50px;
                    -webkit-border-radius: 3px;
                    -moz-border-radius: 3px;
                    border-radius: 3px;
                    display: flex;
                    flex-wrap: wrap;


                    .gift-title {
                        width: 100%;
                        text-align: center;
                        color: #6E1A1A;
                        font-size: 14px;
                        font-weight: bold;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 40px;
                    }

                    .gift-container {
                        width: 100%;
                        height: 100px;
                        overflow-y: scroll;
                        padding: 0 20px;

                        li {
                            list-style: none;
                            font-size: 14px;
                            height: 20px;
                            color: #A84848;
                        }
                    }
                }
            }

            .warn {
                position: absolute;
                top: 695px;
                width: 100%;

                .inner {
                    margin: 0 50px;
                    color: #fff;
                    font-size: 13px;
                    text-align: center;
                }
            }

            .rules {
                position: absolute;
                top: 670px;
                width: 100%;
                text-align: center;
                font-size: 14px;
                color: #fff;
            }
        }
    }

    .empty-action {
        width: 100%;
        background: #fff;
        display: flex;

        .inner {
            flex: 1;
            margin: 100px 50px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            img {
                width: 100px;
                height: 100px;
            }

            p {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: 10px;
            }

            .p1 {
                font-size: 18px;
                color: #333;
                font-weight: bold;
                margin-top: 30px;
            }

            .p2 {
                font-size: 14px;
                color: #939393;
            }
        }
    }
</style>